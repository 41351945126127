import React from 'react';
import { DownloadWraperPhil } from './download.style';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import logo from '../../assets/images/yushLogo.png';

const DownloadPhil = ({ recipe, image }) => {
  const [imgSrc, setImgSrc] = React.useState(logo);

  const genImage = () => {
    htmlToImage.toPng(document.getElementById('body'), { pixelRatio: 1 }).then(function (dataUrl) {
      download(dataUrl, 'recipe.png');
    });
  };

  const showImage = () => {
    if (image) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImgSrc(e.target.result);
      };

      reader.readAsDataURL(image[0]);
    }
  };

  React.useLayoutEffect(() => {
    showImage();
    // genImage();
  }, []);

  return (
    <DownloadWraperPhil>
      {/* <button onClick={genImage}>dfdf</button> */}
      {/* <div size='A4' className='page'> */}
      {recipe ? (
        <div id='body' style={{ backgroundImage: `url(${imgSrc})` }}>
          <div>
            <header>
              <section id='page'>
                <h5 id='title'>{recipe.name}</h5> <em id='serves'>{recipe.yields} Portionen</em>
                {recipe.description ? <p>{recipe.description}</p> : null}
              </section>
            </header>
            <article>
              <section id='ingredients'>
                <h6>Zutaten</h6>
                {recipe.ingredients.map((elem, index) => {
                  return <p key={index}>{elem}</p>;
                })}
              </section>

              {/* <section id='instructions'>
                <h6>Zubereitung</h6>
                <p>{recipe.instructions.join(' \n')}</p>
              </section> */}
            </article>
            {/* <img id='recipeImage' src={imgSrc} width='350' height='300' alt='your image' /> */}
          </div>

          {/* <div className='bottom'>
            {/* <p className='footer'>Homemade food by Stailey</p>
            <div className='yush'>
              <p className='yushText'>created by</p>
              <img src={logo} width='35' height='15' className='yushImg' />
            </div>
          </div> */}
        </div>
      ) : null}
      {/* </div> */}
    </DownloadWraperPhil>
  );
};

export default DownloadPhil;
